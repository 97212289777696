import * as React from "react";
import { Flex, Box } from "rebass";
import { Icon } from "antd";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  services?: Array<{
    frontmatter: {
      icon: string;
      name: string;
      description: string;
    };
  }>;
}

const HowSection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  services,
}) => {
  return (
    <PatternBackground patternType={2}>
      <Section>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent="center"
            alignItems="stretch"
            mt={[0, 0, 7]}
          >
            {services.map((service, index) => (
              <StyledBox
                key={index}
                width={[1, 1, 1 / 3]}
                pl={[0, 0, index === 0 ? 0 : 4]}
                pr={[0, 0, index === services.length ? 0 : 4]}
                mb={[4, 4, 0]}
              >
                <Fade bottom delay={400}>
                  <div>
                    <Icon
                      type={service.frontmatter.icon}
                      style={{ fontSize: 35 }}
                    />
                    <h4>{service.frontmatter.name}</h4>
                    <p>
                      {service.frontmatter.description.substr(0, 132)}
                      {service.frontmatter.description.length > 132 && "..."}
                    </p>
                  </div>
                </Fade>
              </StyledBox>
            ))}
          </Flex>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default HowSection;

const StyledBox = styled(Box)`
  & > div {
    background-color: ${props => props.theme.colors.grey};
    color: ${props => props.theme.colors.background};
    height: 100%;
    padding: 45px 30px;
    border-radius: 15px;
    transition: color 0.2s ease-in-out, backgroundColor 0.2s ease-in-out;
  }
  & h4 {
    color: ${props => props.theme.colors.background};
    margin: 30px 0;
    font-weight: normal;
    font-size: 22px;
    transition: color 0.2s ease-in-out, backgroundColor 0.2s ease-in-out;
  }
  & p {
    opacity: 0.8;
    font-size: 17px;
    margin-bottom: 0;
  }
`;
