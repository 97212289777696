import * as React from "react";
import { Flex, Box } from "rebass";
import { Icon } from "antd";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  cards: Array<{
    icon: string;
    title: string;
    number: number;
  }>;
}

const StatsSection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  cards,
}) => {
  return (
    <PatternBackground patternType={2}>
      <Section center>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent="center"
            alignItems="stretch"
            mt={[0, 0, 7]}
          >
            {cards.map((card, index) => (
              <StyledBox
                key={index}
                width={[1, 1, 1 / 4]}
                pl={[0, 0, index === 0 ? 0 : 4]}
                pr={[0, 0, index === cards.length ? 0 : 4]}
                mb={[4, 4, 0]}
              >
                <Fade bottom delay={400}>
                  <div>
                    <Icon type={card.icon} style={{ fontSize: 40 }} />
                    <h4>{card.number}</h4>
                    <p>{card.title}</p>
                  </div>
                </Fade>
              </StyledBox>
            ))}
          </Flex>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default StatsSection;

const StyledBox = styled(Box)`
  & > div {
    background-color: ${props => props.theme.colors.grey};
    color: ${props => props.theme.colors.background};
    height: 100%;
    padding: 45px 30px;
    border-radius: 15px;
    transition: color 0.2s ease-in-out, backgroundColor 0.2s ease-in-out;
  }
  & h4 {
    color: ${props => props.theme.colors.background};
    margin: 30px 0;
    font-weight: bold;
    font-size: 50px;
    transition: color 0.2s ease-in-out, backgroundColor 0.2s ease-in-out;
  }
  & p {
    opacity: 0.8;
    font-size: 17px;
    margin-bottom: 0;
  }
`;
