import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroSection from "../components/index/HeroSection";
import PortfolioSection from "../components/index/PortfolioSection";
import ContactSection from "../components/index/ContactSection";
import StatsSection from "../components/index/StatsSection";
import ClientsSection from "../components/index/ClientsSection";
import HowSection from "../components/index/HowSection";
import WhySection from "../components/index/WhySection";
import AboutSection from "../components/index/AboutSection";

const pageSchemaContent = {
  name: "Chetan Yadav",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://imchetanyadav.com/",
      },
    ],
  },
};

export default (props: any) => {
  const {
    hero,
    about,
    service,
    why,
    casestudy,
    stats,
    testimonial,
    contact,
  } = props.data.allFile.edges[0].node.childPageJson;

  return (
    <Layout>
      <SEO pageSchemaContent={pageSchemaContent} />
      <HeroSection {...hero} />
      <AboutSection {...about} />
      <HowSection {...service} />
      <WhySection {...why} />
      <PortfolioSection {...casestudy} />
      <StatsSection {...stats} />
      <ClientsSection {...testimonial} />
      <div id="contact">
        <ContactSection {...contact} />
      </div>
    </Layout>
  );
};

export const indexQuery = graphql`
  query {
    allFile(
      filter: { name: { eq: "index" }, sourceInstanceName: { eq: "content" } }
    ) {
      edges {
        node {
          childPageJson {
            hero {
              focusText
              title
              subtitle
              buttonText
            }
            about {
              focusText
              title
              subtitle
              buttonText
            }
            service {
              focusText
              title
              subtitle
              services {
                frontmatter {
                  icon
                  title
                  name
                  description
                }
              }
            }
            why {
              focusText
              title
              subtitle
              advantages {
                icon
                title
                description
              }
            }
            casestudy {
              focusText
              title
              subtitle
              buttonText
              projects {
                frontmatter {
                  cover {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  video
                  title
                  name
                  tagline
                  tech
                  color
                }
              }
            }
            stats {
              focusText
              title
              subtitle
              cards {
                icon
                title
                number
              }
            }
            testimonial {
              focusText
              title
              subtitle
            }
            contact {
              focusText
              title
              subtitle
            }
          }
        }
      }
    }
  }
`;
