import * as React from "react";
import { Flex, Box } from "rebass";
import { Icon } from "antd";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  advantages: Array<{
    icon: string;
    title: string;
    description: string;
  }>;
}

const WhySection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  advantages,
}) => {
  return (
    <PatternBackground patternType={1}>
      <Section>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent="center"
            alignItems="stretch"
            mt={[0, 0, 7]}
          >
            {advantages.map((advantage, index) => (
              <StyledBox
                key={index}
                width={[1, 1, 1 / 3]}
                pl={[0, 0, index === 0 ? 0 : 4]}
                pr={[0, 0, index === advantages.length ? 0 : 4]}
              >
                <Fade bottom delay={400}>
                  <div>
                    <span className="icon-container">
                      <Icon type={advantage.icon} style={{ fontSize: 55 }} />
                    </span>
                    <h4>0{index + 1}</h4>
                    <h4>{advantage.title}</h4>
                    <p>{advantage.description}</p>
                  </div>
                </Fade>
              </StyledBox>
            ))}
          </Flex>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default WhySection;

const StyledBox = styled(Box)`
  & > div {
    color: ${props => props.theme.colors.foreground};
    height: 100%;
    padding: 30px 0;
    transition: color 0.2s ease-in-out;
  }
  & .icon-container {
    display: inline-block;
    padding: 15px;
    border-radius: 50%;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.grey};
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
  & h4 {
    color: ${props => props.theme.colors.foreground};
    margin: 30px 0;
    font-weight: normal;
    font-size: 22px;
    transition: color 0.2s ease-in-out;
  }
  & p {
    opacity: 0.8;
    font-size: 17px;
    margin-bottom: 0;
  }
`;
