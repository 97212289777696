import * as React from "react";
import { Flex, Box } from "rebass";
// @ts-ignore
import Scrollchor from "react-scrollchor";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, PrimaryButton, Para } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  buttonText?: string;
}

const HeroSection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  buttonText = "Let's talk",
}) => {
  return (
    <PatternBackground patternType={1}>
      <Section style={{ position: "relative", height: "100vh" }}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%", maxWidth: "600px" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <Fade bottom delay={400}>
            <Box>
              <Scrollchor to="#contact">
                <PrimaryButton>{buttonText}</PrimaryButton>
              </Scrollchor>
              {/* <Button
              shape="circle"
              icon="caret-right"
              size="large"
              style={{ marginLeft: 15 }}
            /> */}
            </Box>
          </Fade>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default HeroSection;
