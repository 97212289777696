import * as React from "react";
import { Flex, Box } from "rebass";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para } from "../basic";
import PatternBackground from "../PatternBackground";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
}

const ClientsSection: React.FC<Props> = ({ focusText, title, subtitle }) => {
  return (
    <PatternBackground patternType={4}>
      <Section>
        <Flex
          flexDirection={["column", "column", "row"]}
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <StyledBox width={[1, 1, 1 / 2]} pr={[0, 0, 5]}>
            <Fade bottom>
              <FocusText>{focusText}</FocusText>
            </Fade>
            <Fade bottom>
              <Title>{title}</Title>
            </Fade>
            <Fade bottom delay={200}>
              <Para>{subtitle}</Para>
            </Fade>
            <svg
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ width: 40, marginBottom: 20 }}
            >
              <g>
                <path
                  fill="currentColor"
                  d="M224.001,74.328c5.891,0,10.667-4.776,10.667-10.667s-4.776-10.667-10.667-10.667
		c-123.653,0.141-223.859,100.347-224,224v64c-0.185,64.99,52.349,117.825,117.338,118.01
		c64.99,0.185,117.825-52.349,118.01-117.338c0.185-64.99-52.349-117.825-117.338-118.01c-38.374-0.109-74.392,18.499-96.506,49.861
		C23.48,163.049,113.514,74.485,224.001,74.328z"
                />
                <path
                  fill="currentColor"
                  d="M394.667,223.662c-38.154,0.03-73.905,18.63-95.829,49.856
		c1.976-110.469,92.01-199.033,202.496-199.189c5.891,0,10.667-4.776,10.667-10.667s-4.776-10.667-10.667-10.667
		c-123.653,0.141-223.859,100.347-224,224v64c0,64.801,52.532,117.333,117.333,117.333S512,405.796,512,340.995
		S459.469,223.662,394.667,223.662z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            <Fade bottom delay={400}>
              <Para>
                During his tenure with us, we found him to be quite hardworking,
                sincere and result oriented. We wish him all success in his
                future endeavors and career.
              </Para>
            </Fade>
            <Fade bottom delay={400}>
              <h4>
                <b>Dr S. K. Nigam</b>
                <br />
                <span>Director (HR & Admin)</span>
              </h4>
            </Fade>
          </StyledBox>
          <Box width={[1, 1, 1 / 2]} pl={[0, 0, 5]}>
            <Fade delay={400}>
              <img src="/img/clients.png" alt="clients" />
            </Fade>
          </Box>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default ClientsSection;

const StyledBox = styled(Box)`
  & h4 {
    font-weight: normal;
    font-size: 20px;
  }
  & h4 span {
    font-size: 14px;
    display: inline-block;
    margin-top: 15px;
    text-transform: uppercase;
  }
`;
